@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


div.affiliateList {
  justify-content: flex-start;
  @include stretch(math.div(size("gutter--medium-large"), 2));
}

.item {
  padding: math.div(size("gutter--medium-large"), 2);

  @media (max-width: (breakpoint("mini") - 1px)) {
    flex: 0 0 100%;
  }
}
