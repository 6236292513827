@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.headings {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin: size("gutter") 0 size("gutter--large") 0;

  h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h3 {
    color: var(--textMuted);
    margin-left: size("gutter");
    font-size: size("font");
    line-height: font("line-height--paragraph");
    margin-right: auto;
  }

}

.recordsButton {
    padding: size("gutter--small") size("gutter--large");
    margin-top: size("gutter--large");
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--light);
    background: var(--primary);
    letter-spacing: font("uc-letter-spacing");
    font-size: size("font--medium-small");
    transition: background-color transition("duration--atoms") transition("ease-in");
    cursor: pointer;
    &:hover {
      color: var(--border);
      background-color: var(--primary-d1);
      text-decoration: none!important;
    }
}

.dropdown {
  width: 220px;
  margin-right: size("gutter--large");

  main {
    max-height: calc(40vh);
    overflow: auto;
  }
}