@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$activeFiltersMinHeight: 70px;
$textMarginLeft: 8px;
$iconSideLength: 15px;

.activeFilters {
  display: flex;
  padding: size("gutter--large");
  min-height: $activeFiltersMinHeight;
  flex-wrap: wrap;
  justify-content: center;

  &Left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -size("gutter--large");
  }

  &Right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &Top {
    display: flex;
    align-items: center;

    & > * {
      margin-right: size("gutter");

      @media (max-width: (breakpoint("small") - 1px)) {
        margin-right: 0;
        margin-bottom: size("gutter");
      }
    }

    @media (max-width: (breakpoint("small") - 1px)) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &Bottom {
    display: flex;
    align-items: center;
  }

  &Count {
    margin-bottom: 0;
  }

  .totalCount {
    display: none;
  }

  &Container {
    .clearbutton {
      &Mobile {
        color: var(--invertedText);
        background: var(--primary);
        height: $activeFiltersMinHeight;
        width: 100%;
        font-size: size("font--medium-small");
        font-weight: 500;
        border-radius: 0;
        text-transform: uppercase;

        @media (min-width: breakpoint("tiny")) {
          display: none;
        }
      }

      &Desktop {
        color: var(--primary);
        display: none;

        @media (min-width: breakpoint("tiny")) {
          display: block;
        }
      }
    }

    @media (max-width: breakpoint("tiny")) {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: size("gutter");
    }
  }

  &Empty {
    min-height: $activeFiltersMinHeight;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--border);

    p {
      margin: 0;
      color: var(--textMuted);
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-direction: column !important;
  }
}

.activeFilter {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  align-content: center;

  .icon {
    background-color: var(--primary);
    border-radius: 1px;
    width: $iconSideLength;
    height: $iconSideLength;

    svg {
      fill: var(--invertedText);
      color: var(--invertedText);
      height: 100%;
      width: 100%;
      vertical-align: unset;
    }
  }

  .text {
    margin-left: $textMarginLeft;
    line-height: 1.1;
    text-transform: capitalize;
    color: color("text");
    font-size: size("font--small");
  }
}

.hideEmptyFilters {
  flex-direction: row;
  padding: 0;

  .activeFiltersLeft {
    margin-left: 0;
  }
}
