@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
    cursor: pointer;
    user-select: none;
    position: relative;
}

.dropdown {
    background-color: var(--background);
    color: var(--light);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
    position: absolute;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 72px;
    width: 240px;
    max-height: 400px;
    overflow: auto;
    cursor: initial;
    z-index: 901;

    a:not(#foo) {
        padding: size("gutter--small");
        color: var(--light);
        background-color: var(--background);
        text-decoration: none;
        margin-left: 0;

        &:hover {
            text-decoration: underline;
        }
    }

    > * + * {
        border-top: 1px solid color("border--muted");
    }
}
