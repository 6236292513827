@use "sass:list";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

/*
  Global typography rules
*/

$block: "typography";

@mixin headings($sizes...) {
  $uniqueId: unique-id();

  %#{$uniqueId} {
    @content;
  }

  @each $size in $sizes {
    h#{$size},
    .h#{$size} {
      @extend %#{$uniqueId};
    }
  }
}


@include headings(1, 2, 3, 4, 5, 6) {
  margin: 0;
  font: 700 list.slash(1em, font("line-height--heading")) var(--fontHeader), var(--fontHeaderFallback, #{font("fallback")});
  text-transform: uppercase;
  @include vertical-margins(size("gutter--large"));
}

h1,
.h1 {
  line-height: 1;
  font-size: size("h1");

  @media (max-width: (breakpoint("small") - 1px)) {
    font-size: size("h1") * 0.75;
  }

  @include vertical-margins(size("gutter--large") * 1.25);
}

h2,
.h2 {
  font-size: size("h2");
}

h3,
.h3 {
  font-size: size("h3");
  line-height: font("line-height--medium-small");
  letter-spacing: 0.02em;
}

h4,
.h4 {
  font-size: size("h4");
  line-height: font("line-height--paragraph");
  margin-bottom: size("gutter");
}

h5,
.h5 {
  font-size: size("h5");
}

h6,
.h6 {
  font-size: size("h6");
}

%anchor {
  color: var(--primaryAlt);
}

p,
.paragraph {
  line-height: font("line-height--paragraph");

  @include vertical-margins(size("gutter"));

  &,
  > i,
  > b,
  > em,
  > strong {
    > a {
      @extend %anchor;
    }
  }
}

.lead {
  font-size: font("size--large");
}

.anchor {
  @extend %anchor;
}

mark {
  padding: 0.2em;
  background: color("mark");
}

strong,
b {
  font-weight: 700;
}

:global {
  .#{$block} {
    line-height: font("line-height");

    ol,
    ul {
      line-height: font("line-height");
      padding-left: size("gutter--small");

      @include vertical-margins(size("gutter--small"));
      list-style-position: inside;

      ul {
        list-style-type: circle;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      line-height: font("line-height");

      + p {
        margin-top: 0;
      }
    }

    table {
      @include vertical-margins(size("gutter--small"));
    }

    td,
    th {
      text-align: left;
      vertical-align: baseline;
      line-height: font("line-height--heading");
    }

    tr {
      &:not(:first-child) {
        td,
        th {
          padding-top: 0.5em;
        }
      }

      &:not(:last-child) {
        td,
        th {
          padding-bottom: 0.5em;
        }
      }
    }


    td:not(:first-child) {
      padding-left: size("gutter--small");
    }

    [style*="font-size"] {
      font-size: inherit !important;
    }
  }
}
