@import "ui-components/_variables.scss";

$padding: size("gutter--large");
$padding--small: 1.2rem;

.container {
  background: var(--primary);
  height: size("header-height");
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: fixed;
  z-index: z-index("system");
  top: 0;
  left: 0;
  right: 0;

  &:not(.hamburgerOpen) svg {
    color: var(--light);
  }

  &.fullMenu {
    margin-bottom: size("gutter--large") * 2;

    @media (min-width: breakpoint("medium")) {
      height: size("header-height") + size("sub-header-height");
    }
  }

  @media (max-width: breakpoint("small")) {
    &.hasMobileSubNav {
      height: size("header-height") + size("sub-header-height") !important;
    }
  }

  @media (max-width: breakpoint("small")) {
    height: size("header-height");
  }

  @media (min-width: breakpoint("small")) {
    &.empty {
      height: size("header-height") !important;
    }
  }

}
