@import "../../node_modules/@crossroads/ui-components/src/ui-components/Carousel.scss";

.dot {
  border: none;

  &:not(.active) {
    padding: 10px;
  }

  &:not(.active):after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--primaryAlt);
  }

  &.active {
    font-weight: bold;
    padding: 6px;
    background: var(--primaryAlt);
  }
}

.prev, .next {
  color: var(--primaryAlt);
  padding: 0 size("gutter");
}

.prev {
  transform: rotate(180deg);
}

.next {
  transform: inherit;
}

.dot {
  @media (max-width: breakpoint("tiny")) {
    margin-right: 0.5em;
  }
}
