@import "ui-components/_variables.scss";

:export {
  small: strip-unit(breakpoint(small));
}

.block {
  color: var(--light);
  font-size: size("font");

  &.onHomeView {
    background: var(--background);
  }
}

.inner {
  background: linear-gradient(to right, color("gradient-primary") 0%, color("gradient-secondary"));
  margin-top: size("gutter--module");
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: size("gutter--medium");
}

.wrapper {
  display: flex;
  gap: 6rem;
  flex-direction: column;
  padding-top: size("gutter--large");
  padding-bottom: size("gutter");

  @media (max-width: breakpoint("small")) {
    gap: 0;
  }
}

.section {
  display: flex;
  justify-content:space-between;

  .left,
  .right {
    width: 50%;
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: size("gutter");
    margin-right: size("gutter--large");
    flex-wrap: wrap;

    @media (max-width: breakpoint("small")) {
      flex-direction: column;
      align-items: initial;
      
      button {
        justify-content: left;
      }
    }
  }

  .right {
    margin-left: size("gutter--large");
    text-align: right;

    @media (max-width: breakpoint("small")) {
      text-align: left;
    }
  }

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
    margin: size("gutter") 0;

    .left,
    .right {
      width: 100%;
      margin: initial;
      max-width: 650px;
    }

    .right {
      margin: size("gutter") 0;
    }

    .left {
      margin-bottom: size("gutter--large");
    }
  }

  .link {
    color: var(--light) !important;
  }
}

.infoLink {
  line-height: font("line-height--paragraph");
  
  .link {
    line-height: font("line-height--paragraph");
  }

  @media (max-width: breakpoint("small")) {
    padding-left: 0;

    &Info {
      font-size: size("font");
    }
  }
}


.infoLink:not(:last-child) {
  display: flex;

  &::after {
    padding-left: size("gutter");
    content: " | "
  }
  @media (max-width: breakpoint("small")) {
    &::after {
      content: none;
    }
  }
}

.link {
  color: var(--light) !important;
  font-size: size("font");

  &:hover {
    text-decoration: underline;
  }
}


.links {
  @media (max-width: breakpoint("large")) {
    padding-top: size("gutter"); 
  }

  .left {
    width: 65%
  }

  .right {
    width: 35%
  }
} 

.infoLinks {
  .left {
    flex: 1 1 100%;
  }
}

.logoWrapper {
  margin-bottom: size("gutter");
}
