@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$paddingTopLarge: 250px;
$paddingTopSmall: 150px;
$maxWidthLoginForm: 425px;

:export {
  small: strip-unit(breakpoint(small));
}

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 0 auto;
  background-color: var(--primary);
  color: var(--light);

  h1 {
    font-size: size("font--title");
  }
}

.header {
  padding: 0 size("gutter--larger");
  text-align: center;

  &Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: size("gutter--huge");
  }

  a {
    color: var(--light);
    font-weight: bold;
    font-size: size("font--medium-small");
  }

  svg {
    color: var(--light);
  }
}

.wrapper {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  flex-direction: column;
}

.content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin-top: size("gutter--larger");

  @media (min-width: 376px) {
    justify-content: center;
    margin: 0;
  }
}

.footer {
  flex-shrink: 0;
  width: 100%;
  background-color: var(--primary);
  color: var(--light);
  padding: size("gutter");

  a {
    color: var(--light);
  }

  .footerInner {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .cookieButton {
      &:hover {
        text-decoration: underline;
      }
    }

    .spacing {
      margin-right: size("gutter--small");
    }
  }
}