@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$maxWidthLoginForm: 425px;

.login_form {
  min-width: $maxWidthLoginForm;

  @media (max-width: (breakpoint("small") - 1px)) {
    min-width: 0;
  }
}
