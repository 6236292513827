@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.card {
  display: block;
  background: var(--secondary);
  border-radius: var(--borderRadius);
  width: 100%;
  position: relative;

  &:hover {
    text-decoration: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--text);
  transition: color transition("duration--atoms") transition("ease-in");

  &Text {
    margin: size("gutter");
  }

  h2 {
    font-family: var(--fontBody),
    var(--fontBodyFallback, #{font("fallback")});
    text-transform: none;
    font-size: size("font");
    color: var(--text);
    margin: 0;

    .card:hover & {
      text-decoration: underline;
    }
  }

  .image {
    padding-bottom: 56.25%;

    img {
      border-radius: var(--borderRadius) var(--borderRadius) 0 0;
      position: absolute;
      object-fit: cover;
      min-width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .description {
    line-height: font("line-height--paragraph");
    margin-top: size("gutter");
    padding: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-clamp: 4;

    video {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }

    img {
      width: 100%;
      max-height: 250px;
      object-fit: cover;
    }

    a {
      z-index: z-index("popup");
    }
  }

  .meta {
    display: flex;
    flex-direction: column;
    position: static;
    margin: auto size("gutter") size("gutter");
  }

  .readMore {
    color: var(--primaryAlt);
    font-weight: 500;

    .card:hover & {
      text-decoration: underline;
    }
  }

  .tags {
    display: flex;
    gap: size("gutter--mini");
    margin-top: size("gutter");
  }

  .tag {
    display: inline-block;
    background-color: var(--primary);
    font-size: size("font--mini");
    color: var(--invertedText);
    border-radius: calc(var(--borderRadius) - 2px);
    padding: calc(size("grid-gutter") / 2) size("grid-gutter");
  }
}

.link {
  position: static;
  padding-top: size("gutter");

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }
}

.shadow {
  opacity: 0;
  border-radius: var(--borderRadius);
  transition: opacity transition("duration--atoms") transition("ease-in");
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .card:hover & {
    opacity: 1;
  }
}

.dummy {
  .card {
    width: 100%;
  }

  .content {
    display: block;
  }

  .title,
  .image,
  .description {
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .title {
    height: size("font--large");
  }

  .description {
    height: size("font--large") * 5;
    margin-top: size("gutter");
  }
}
