@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  medium: breakpoint("medium");
  small: breakpoint("small");
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: size("gutter--huge");
}

.banner {
  margin-top: size("gutter--section");

  h2 {
    font-size: 2rem;
  }
}

.hero {
  min-height: 421px;
}

.carousel {
  margin-left: -#{math.div(size("gutter"), 2)};
  margin-right: -#{math.div(size("gutter"), 2)};
  min-width: calc(100% + #{size("gutter")});
}

.item {
  display: flex;
  flex-direction: row !important;
  min-height: 400px;

  img {
    order: 2;
    @media (min-width: (breakpoint("medium") + 1px)) {
      max-width: calc(100% - 440px);
    }
  }
  @media (min-width: (breakpoint("medium") + 1px)) {
    max-height:400px;
     > div {
      flex-shrink: 0;
      width: 50%;
    }
  }

  @media (max-width: breakpoint("medium")) {
    flex-direction: column !important;
    max-height: unset;

    img {
      order: unset;
    }
  }

}