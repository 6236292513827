@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  medium: strip-unit(breakpoint("medium"));
}

$item_margin: size("gutter--mini") * 2;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.heading {
  @media (max-width: breakpoint("large")) {
    margin-top: size("gutter--larger") !important;
  }
}

.description {
  font-size: size("font--medium-small");
  color: var(--textMuted);
  margin-bottom: size("gutter--huge");
  max-width: 45rem;

  &.column {
    column-count: 2;
    max-width: initial;
  }
}

.items {
  @media (min-width: breakpoint("medium")) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    @include stretch(math.div(size("gutter"), 2));
  }
  .mobileCol {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.leftCol, .rightCol {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .item {
    width: fit-content;
  }
}


.item {
  animation: fadeIn transition("duration--organisms") transition("ease-ease") forwards;
  opacity: 0;
  width: calc(50% - #{$item_margin});
  padding-bottom: size("gutter") !important;

  @media (max-width: breakpoint("medium")) {
    width: 100%;
  }

  @media (min-width: breakpoint("medium")) {
    display: flex;
    align-items: flex-start;
    margin: 0 size("gutter--mini");
  }

  ul {
    line-height: 1.3;
    padding-left: size("gutter--medium");
  }

}

.no_items {
  padding-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter"), 2);
    padding-bottom: size("gutter--large");
  }
}
