@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  padding: size("gutter--larger");
  width: 100%;
}

.noScriptParagraph {
  text-align: center;
  padding-top: size("gutter");
  color: color("negative");
}

.wrapper {
  max-width: 21rem;
  margin: auto;
}

.form form {
  display: flex;
  flex-direction: column;
  color: var(--text);

  button {
    white-space: nowrap;
  }
}

.bottom {
  margin-top: size("gutter");
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: size("gutter");

  @media (max-width: (breakpoint("mini") - 1px)) {
    flex-direction: column;
  }
}

.resetPasswordDescription {
  text-align: center;
  font-size: size("font") !important;
  margin-bottom: 0;
}

.no_account {
  @media (min-width: (breakpoint("mini") + 1px)) {
    text-align: right;
  }
}

.forgot_password,
.no_account {
  display: flex;
  align-items: center;

  p,
  a {
    margin: 0;
  }

  a,
  span {
    color: var(--secondary);
  }
}