@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.mainWrapper {
  @media (min-width: breakpoint("medium")) {
    padding-top: size("gutter--huge") !important;
  }
}

.contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: size("gutter--huge");
  width: 100%;

  @media (max-width: breakpoint("medium")) {
    grid-template-columns: 1fr;
  }

  .item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: size("gutter--medium");

    p {
      margin-top: size("gutter--mini");
      margin-bottom: size("gutter--mini");
    }

    @media (max-width: breakpoint("medium")) {
      grid-template-columns: auto 1fr;
    }

    @media (max-width: breakpoint("tiny-small")) {
      grid-template-columns: 1fr;
    }
  }
}

.mainContact {
  .item {
    display: grid;
    gap: size("gutter--medium");
    grid-template-columns: min-content 1fr;

    p:not(.name) {
      margin-top: size("gutter--mini");
      margin-bottom: size("gutter--mini");
    }
    .name {
      margin-top: size("gutter--mini");
      margin-bottom: size("gutter--mini");
    }
    @media (max-width: breakpoint("medium")) {
      grid-template-columns: 1fr;
    }
  }
}

.companies {
  display: grid;
  grid-template-columns: 1fr;
  gap: size("gutter--huge");

  .item {
    display: grid;
    grid-template-columns: min-content 0.7fr;
    gap: size("gutter--medium");

    p:not(.name) {
      margin-top: size("gutter--mini");
      margin-bottom: size("gutter--mini");
    }

    .name {
      margin-top: size("gutter--mini");
      margin-bottom: size("gutter--mini");
    }

    button {
      border: none;
      background: none;
      color: var(--primary);
      padding: 0;
    }

    @media (max-width: breakpoint("medium")) {
      grid-template-columns: auto 1fr;
    }

    @media (max-width: breakpoint("tiny-small")) {
      grid-template-columns: 1fr;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 90px;

    &Height {
      max-height: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    }
  }

  .active {
    .text {
      display: initial;

      &::after {
        content: none;
      }
    }
  }
}

.title {
  font-size: size("font--huge");
  margin-bottom: size("gutter");

  &:first-of-type {
    margin-top: size("gutter--huge");
  }

}

.description {
  margin-bottom: size("gutter--huge");
}

.imageWrapper {

  width: 290px;
  height: 145px;

  @media (min-width: breakpoint("medium")) {
    min-width: 240px;
    width: auto;
  }
  
  display: flex;
  align-items: center;
  margin-top: size("gutter--small");
  border: 1px solid var(--border);
  border-radius: var(--borderRadius);
}

.image {
  padding: 2rem;
  max-height: 100%;
  margin: auto;
}

.name {
  font-weight: bold;
}
