@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.headings {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin: size("gutter") 0 size("gutter--large") 0;

  h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h3 {
    color: var(--textMuted);
    margin-left: size("gutter");
    font-size: size("font");
    line-height: font("line-height--paragraph");
    margin-right: auto;
  }

  .recordsButton {
    padding: size("gutter--small") size("gutter--large");
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-wrap: wrap;
    justify-content: flex-start;

    > a {
      flex: 0 0 100%;
      margin-top: size("gutter--small");
    }
  }
}
