@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$padding: size("gutter--large");
$padding--small: 1.2rem;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0.7;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }
}

.block {
  z-index: z-index("overlay") + 1;
  height: size("header-height");
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: breakpoint("large");
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding--small;
  padding-right: $padding--small;

  @media (min-width: breakpoint("small")) {
    padding-left: $padding;
    padding-right: $padding;
  }
}

.logo {
  flex: 0 0 auto;
  justify-content: center;

  div img {
    max-height: 36px;
  }

  svg {
    overflow: inherit;
  }
}

.companyName {
  padding-left: size("gutter");
  font-size: size("font");
}

.nav {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.links {
  display: flex;
  z-index: 2;
}

.login {
  margin-left: size("gutter--large");
}

.right {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}

.miniCartWrapper {
  max-width: breakpoint("large");
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
}

.miniCart {
  top: size("header-height") !important;
  max-height: calc(100svh - (#{size("header-height")} + 1rem)) !important;
}

.compactMenu {
  margin-right: auto;
  margin-left: size("gutter--small");
  display: flex;
  align-items: center;
  > div {
    display: flex;
    color: #fff;
    align-items: center;
    margin-right: 1.13rem;
    p {
      margin-top: 0;
    }
  }
}
.item {
  color: var(--light);
  font-size: size("font--small");
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover:not(.item__customerBadge) {
    text-decoration: underline;
  }

  &.active:not(.item__customerBadge) {
    color: var(--secondary);
    text-decoration: underline;
  }

  &__customerBadge {
    &:hover {
      text-decoration: none;

      p:first-child {
        text-decoration: underline;
      }
    }

    &.active {
      p:first-child {
        text-decoration: underline;
      }
    }
  }

  & + & {
    margin-left: size("gutter--medium");
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  &__categories {

    .chevron {
      transition: all transition("duration--organisms") transition("ease-ease");
      top: 0;
      transform: rotate(90deg);
      margin-left: size("gutter--mini");
    }
  }

  @media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
    font-size: 14px;
  }
}

.item.active .chevron {
  transform: rotate(90deg) scaleX(-1);
}

.searchIcon {
  margin-right: size("gutter--mini");
  width: 22px;
  height: 22px;
}

.triangle {
  $triangle-size: 0.6em;

  @include triangle(top, $triangle-size, var(--light));
  left: 50%;
  margin-left: -$triangle-size;
  top: size("sub-header-height") + 2px;
  display: none;

  .item.active & {
    display: block;
  }
}

.dim {
  position: fixed;
  background: rgba(#fff, 0.8);
  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
  z-index: z-index("system");
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;

  &.cart {
    top: size("header-height");
    z-index: z-index("overlay");
  }
}

.expiresNotice {
  &:hover {
    text-decoration: none;

    > .notice {
      text-decoration: underline;
    }
  }
}

.exclamation {
  font-size: size("font--large");
}

.notice {
  font-size: size("font--mini");
  display: flex;
  flex-direction: column;
  margin-left: size("gutter--mini");

  .points {
    font-weight: 500;
  }
}

.districtName {
  margin-left: size("gutter--small");
  font-size: size("font--medium");
}

.adminBtnContainer {
  align-self: center;
  margin-right: size("gutter--small");

  button {
    padding: size("gutter--mini") size("gutter") !important;
    font-size: size("font--small") !important;
  }
}

.shopContainer {
  display: flex;
  padding: size("gutter--small");
  background-color: var(--secondary);
  color: var(--background);
  border-radius: var(--borderRadius);
  margin: size("grid-gutter") 0 size("grid-gutter") size("gutter");
  .item {
    color: currentColor !important;
  }
  svg {
    color: currentColor !important;
  }
}
