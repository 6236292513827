@use "sass:math";

@import "../../../node_modules/@crossroads/ui-components/src/ui-components/Lightbox.scss";
@import "ui-components/_variables.scss";

$background-color: var(--secondary);
$color: var(--primaryAlt);

:export {
  duration: strip-unit(transition("duration--organisms"));
}

@keyframes fadeOut {
  0% {};
  100% { opacity: 0; };
}

@keyframes appearNormal {
  0% { opacity: 0; transform: scale(.85); };
  100% { opacity: 1; transform: scale(1); };
}

@keyframes backgroundAppear {
  0% { opacity: 0; };
  100% { opacity: .9; };
}

.block {
  padding: size("gutter");
  border-radius: size("border-radius");
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  color: $color;
  background-color: $background-color;

  .leave & {
    animation: fadeOut forwards transition("duration--organisms") transition("ease-ease");
  }

  .enter & {
    opacity: 0;
    animation: appearNormal forwards transition("duration--organisms") transition("ease-ease");
    animation-delay: transition("duration--organisms") * .7;
  }

  .fullscreen & {
    height: 100vh;
    width: 100vw;
    border-radius: inherit;
  }

  @media (max-width: breakpoint("small")) {
    height: 100vh !important;
    width: 100vw !important;
    border-radius: inherit;
    overflow: auto;
  }
}

.wrapper {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .fullscreen & {
    background-color: white;
    backface-visibility: hidden;
  }

  @media (max-width: breakpoint("small")) {
    overflow: auto;
    align-items: baseline;
  }
}

.body {
  line-height: 1.5em;
  margin: 0.3em 0;
  color: $color;
}

.overlay {
  background: #fff;
  opacity: 0.95;
  position: absolute;
  inset: 0;

  .enter & {
    animation: backgroundAppear forwards transition("duration--templates") transition("ease-ease");
  }

  .leave & {
    animation: fadeOut forwards math.div(transition("duration--templates"), 2) transition("ease-out");
    animation-delay: math.div(transition("duration--templates"), 2.5);
  }
}
