@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.title {
  font-size: size("font--huge");
}

.introText {
  margin-top: size("gutter--large");
  column-count: 2;

  @media (max-width: breakpoint("small")) {
    column-count: 1;
  }
}

.benefits {
  display: flex;
  flex-direction: row;
  gap: size("gutter--small");
  margin-top: size("gutter--huge");

  h2 {
    font-size: size("font--huge");
  }

  p {
    margin-top: size("gutter--mini");
    margin-bottom: size("gutter--mini");
  }

  .benefitsList {
    margin-top: size("gutter--large");

    li {
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: var(--primary);
        flex-shrink: 0;
        margin-right: size("gutter--small")
      }

      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      line-height: font("line-height--paragraph");
      margin: size("gutter--small") 0;
    }
  }

  .outro {
    margin-top: size("gutter--huge");
    margin-bottom: size("gutter--medium");
  }

  > div {
    flex: 1;
  }

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
  }
}

.faq {
  padding: size("gutter--section") 0 0;

  h2 {
    font-size: size("font--huge");
    margin: 0 !important;
  }
  h3 {
    font-size: size("font--medium");
    margin: 0 !important;
    margin-top: size("gutter--small");
  }
  h3 + p {
    margin-top: size("grid-gutter");
  }
  ul {
    li {
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: var(--primary);
        flex-shrink: 0;
        margin-right: size("gutter--small")
      }

      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      line-height: font("line-height--paragraph");
      margin: size("gutter--small") 0;
    }
  }
}
