@use "sass:math";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.productCarousel {
  margin-top: size("gutter--large");
  width: 100%;
}

.productCarouselList {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  @include stretch(math.div(size("gutter--medium-small"), 2));

  & > div > div:first-child:not(.productCarouselItem) {
    padding: size("gutter") 0;
    margin: calc(0 - #{size("gutter")}) 0;
  }
}

.displays-4 .productCarouselItem,
.productCarouselItem {
  display: flex;
  padding: math.div(size("grid-gutter"), 2) math.div(size("gutter--medium-small"), 2);

  flex: 0 0 math.div(100%, 4) !important;

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 0 0 math.div(100%, 4);
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex: 0 0 math.div(100%, 2);
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    flex: 0 0 math.div(100%, 2);
  }
}
.displays-6 .productCarouselItem {
  display: flex;
  flex: 0 0 math.div(100%, 6) !important;

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 0 0 math.div(100%, 6);
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex: 0 0 math.div(100%, 2);
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    flex: 0 0 math.div(100%, 2);
  }
}

.productCard {
  width: 100% !important;
}

.dummy {
  margin-bottom: size("gutter--large");

  .title {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background--hover") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;

    width: 40%;
  }
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}
