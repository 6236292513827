@import "ui-components/_variables.scss";

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.wrapper {
  @media (max-width: breakpoint("small")) {
    padding: 0;
  }

  @media (min-width: breakpoint("small")) {
    padding-top: size("gutter--large");
  }

  @media (min-width: breakpoint("large")) {
    padding-top: size("gutter--huge");
  }
}

.contentHero,
.dummyHero {
  height: 600px;
  width: 100%;
  max-width: breakpoint("large");
  margin: auto;

  img {
    border-radius: var(--borderRadius);

    @media (max-width: breakpoint("small")) {
      border-radius: 0;
    }
  }

  @media (max-width: breakpoint("small")) {
    height: auto;
  }
}

.dummyHero {
  background-image: linear-gradient(90deg, color("disabled") 0%, color("background--hover") 50%, color("disabled") 100%);
  background-size: 200%;
  animation: shine 1.5s infinite linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: size("gutter--medium");

  p {
    border-radius: var(--borderRadius);
    background-image: linear-gradient(90deg, color("border") 0%, color("background") 50%, color("border") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
    

    &:nth-child(1) {
      max-width: 45%;
    }
    &:nth-child(2) {
      max-width: 50%;
    }
    &:nth-child(3) {
      max-width: 55%;
    }
  }

  @media (max-width: breakpoint("small")) {
    margin-bottom: 66px;
  }
}
