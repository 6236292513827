@import "ui-components/_variables.scss";

.adminButton {

  &:hover {
    text-decoration: none !important;
  }
}

.button {
  &:not(#foo) {
    background-color: var(--primaryAlt);
    border: solid 1px var(--invertedText);
    color: var(--invertedText);

  &:enabled:hover {
      background-color: color-mix(in srgb, var(--primaryAlt) 80%, black 20%);
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    display: flex !important;
    margin-left: auto;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
  }
}
