@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  ts: strip-unit(breakpoint("tiny-small"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  background-color: var(--background);
  color: var(--text);
}

.promotion {
  display: flex;
  background-color: var(--secondary);
  color: var(--primaryAlt);
  content-visibility: auto;

  .left,
  .right {
    width: 50%;
  }

  .left {
    margin-bottom: 0;
    margin-right: size("gutter--large");
  }

  .right {
    margin-left: size("gutter--large");
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: size("gutter--large");
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex-direction: column;

    .left,
    .right {
      width: 100%;
      margin: initial;
    }

    .left {
      &:not(.empty) {
        margin-bottom: size("gutter");
      }
    }
  }
}

.howToEarnImage {
  height: 100%;
  object-fit: cover;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  content-visibility: auto;

  .featuredProductsItems {
    @include stretch(math.div(size("gutter--medium-large"), 2));
  }

  .featuredProductsItem {
    padding: math.div(size("grid-gutter"), 2) math.div(size("gutter--medium-large"), 2);
    padding-bottom: size("gutter--small");
  }

  .title,
  .description {
    max-width: 650px;
  }
}

.cta {
  background-color: var(--light);
  color: var(--primaryAlt);
  border: 1px solid var(--primaryAlt);
  border-radius: var(--borderRadius);
  font-size: size("font--medium-small");
  padding: size("gutter--mini") size("gutter--small");

  &:hover {
    background-color: var(--primaryAlt);
    color: var(--light);
    text-decoration: none;
  }

  &:hover:active {
    background-color: color-mix(in srgb, var(--primaryAlt) 80%, black 20%);
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: size("title-margin");

  @media (max-width: breakpoint("tiny")) {
    flex-direction: column;
  }
}

.featuredProducts,
.earnOnline {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.companyDataIntro {
  @media (max-width: (breakpoint("medium") - 1px)) {
    padding-top: size("gutter--large");
  }
  p,
  a {
    font-size: size("font") !important;
    margin-bottom: 0;
  }
  a {
    text-decoration: underline;
  }
}

@media (max-width: (breakpoint("tiny") - 1px)) {
  .cta,
  .ctaLarge {
    font-size: size("font") !important;
  }
}

.cardList {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  gap: size("gutter--medium-large");
  padding-top: size("gutter--large");
  @media (max-width: (breakpoint("medium") - 1px)) {
    grid-template-columns: repeat(3, 2fr);
  }
  @media (max-width: (breakpoint("tiny") - 1px)) {
    grid-template-columns: repeat(2, 3fr);
  }
  &Item {
    border: 1px solid var(--border);
    border-radius: var(--borderRadius);
    object-fit: contain;
    background-color: #fff;
    width: 100%;
    padding: size("gutter--small");
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &:focus,
    &:hover {
      .customerTag {
        background-color: color-mix(in srgb, var(--primary) 80%, black 20%);
        color: var(--invertedText);
        transition: background 0s;
      }
    }

    .customerTag {
      position: absolute;
      top: -12px;
      background-color: var(--background);
      border: 1px solid var(--primary);
      border-radius: calc(var(--borderRadius) - 2px);
      font-size: size("font--small");
      padding: 0 calc(size("gutter--mini") / 2);
      color: var(--primary);

      p {
        font-size: size("font--small") !important;
      }
    }

    .isCustomer {
      background-color: var(--primary);
      color: var(--invertedText);
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      height: 100px;
    }
  }
  &Image {
    max-width: 150px;
    @media (max-width: (breakpoint("tiny") - 1px)) {
      max-width: 110px;
    }
  }
}

.popularCategories,
.popularProducts {
  padding: 0;
}

.flexHeading {
  margin: 0 size("gutter--large") 0 0;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin: 0 0 size("gutter--medium-large");
  }
}

.wrapper {
  padding-top: 0;
  margin-top: size("gutter--larger");

  & > *:not(:first-child) {
    margin-top: size("gutter--huge");
  }
}