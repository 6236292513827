@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$maxWidthLoginForm: 425px;

.wrapper {
  width: 100%;
  min-width: $maxWidthLoginForm;
  padding: size("gutter--larger");
  margin-bottom: 10px;

  @media (max-width: (breakpoint("small") - 1px)) {
    min-width: 0;
  }
}

.form {
  color: var(--invertedText);
  border-radius: var(--borderRadius);
  align-self: center;
  max-width: 21rem;
  margin: auto;

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    h2 {
      margin: 0;
    }
  }

  .button {
    width: 100%;
  }
}

.closeIcon {
  color: var(--invertedText);
}

.input input {
  color: var(--text);
}
