@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.errorMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: #{size("gutter--large") * 2} 0;

  h2 {
    text-align: center;
  }
}


.recordsButton {
  padding: size("gutter--small") size("gutter--large") !important;
}