@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  color: var(--text);
  border-radius: var(--borderRadius);
  overflow: hidden;
  line-height: font("line-height--medium-small");

  h2 {
    font-size: size("font--huge");
  }

  @media (max-width: breakpoint("medium")) {
    flex-direction: column;
    max-height: unset;

    &:first-child {
      .image {
        order: unset;
      }
    }
  }
}

.image {
  width: 100%;
  object-fit: cover;
}

.wrapper {
  padding: size("gutter--larger");

  @media (max-width: breakpoint("small")) {
    width: 100%;
  }
}
