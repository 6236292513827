@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  ts: strip-unit(breakpoint("tiny-small"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.header {
  margin: 0 0 size("title-margin");
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    margin: 0;
  }
}

.cta {
  padding: size("gutter--mini") size("gutter--small") !important;
}

.currenInfoCarouselOuterContainer {
  width: 100%;
  @include stretch(math.div(size("gutter--medium-large"), 2));

}

.currentInfoItem {
  min-width: 0;
  display: flex;
  align-items: stretch;
  height: 100%;
  flex: 1 1 100%;
  padding: math.div(size("grid-gutter"), 2) math.div(size("gutter--medium-large"), 2) size("grid-gutter");

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 1 1 50%;
  }
  @media (max-width: (breakpoint("small") - 1px)) {
    flex: 1 1 100%;
  }
}

.currentInfoCard {
  display: block;
  background: var(--secondary);
  border-radius: var(--borderRadius);
  width: 100%;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  &Shadow {
    opacity: 0;
    border-radius: var(--borderRadius);
    transition: opacity transition("duration--atoms") transition("ease-in");
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .currentInfoCard:hover & {
      opacity: 1;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--text);
    transition: color transition("duration--atoms") transition("ease-in");

    &Text,
    &Meta {
      margin: size("gutter");
    }

    &Meta {
      display: flex;
      flex-direction: column;
      position: static;
      margin: auto size("gutter") size("gutter");
    }

    h3 {
      font-family: var(--fontBody),
      var(--fontBodyFallback, #{font("fallback")});
      text-transform: none;
      font-size: size("font");
      color: var(--text);
      margin: 0;

      .currentInfoCard:hover & {
        text-decoration: underline;
      }
    }

    &Image {
      padding-bottom: 56.25%;

      img {
        border-radius: var(--borderRadius) var(--borderRadius) 0 0;
        position: absolute;
        object-fit: cover;
        min-width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    &Description {
      line-height: font("line-height--paragraph");
      margin-top: size("gutter");
      padding: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      line-clamp: 4;

      video {
        width: 100%;
        height: auto;
        max-height: 250px;
        aspect-ratio: 16 / 9;
      }

      img {
        width: 100%;
        max-height: 250px;
        object-fit: cover;
      }

      a {
        z-index: z-index("popup");
      }
    }

    .readMore {
      font-weight: 500;

      .currentInfoCard:hover & {
        text-decoration: underline;
      }
    }

    .link {
      position: static;

      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
      }
    }

    .tags {
      display: flex;
      gap: size("gutter--mini");
      margin-top: size("gutter");
    }

    .tag {
      display: inline-block;
      background-color: var(--primary);
      font-size: size("font--mini");
      color: var(--invertedText);
      border-radius: calc(var(--borderRadius) - 2px);
      padding: calc(size("grid-gutter") / 2) size("grid-gutter");
    }
  }
}

.dummy {
  .title {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background--hover") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;

    width: 40%;
  }
  .currentInfoCard div {
    background-color: var(--background);
  }
}
